<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="circularLoading">
    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
      <b-row>
        <b-col lg="12" sm="12">
          <b-table-simple bordered>
            <b-tr>
              <b-th class="text-left" style="width:35%;">{{ $t('externalTraining.complain_re_title') }}</b-th>
              <b-td class="text-left">{{ $i18n.locale === 'en' ? item.complain_title : item.complain_title_bn }}</b-td>
              <b-th class="text-left" style="width:15%;">{{ $t('externalTraining.description') }}</b-th>
              <b-td>{{ $i18n.locale === 'en' ? item.description : item.description_bn }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-left" >{{ $t('elearning_tim.complain_by') }}</b-th>
              <b-td class="text-left">{{ $i18n.locale === 'en' ?  userData.name : userData.name_bn }}</b-td>
              <b-th class="text-left">{{ $t('externalTraining.designation') }}</b-th>
              <b-td>{{ $i18n.locale === 'en' ?  item.designation_en : item.designation_bn }}</b-td>
            </b-tr>
            <b-tr>
              <b-th class="text-left">{{ $t('globalTrans.attachment') }}</b-th>
              <b-td><a  v-if="item.attachment" target = '_blank' :href="baseUrl + 'download-attachment?file=uploads/complain-external/' + item.attachment" title="Attachment" class="mr-3" download><i class="ri-download-cloud-fill"></i></a></b-td>
              <b-th class="text-left">{{ $t('elearning_tim.resolve') }}</b-th>
              <b-td>{{ $i18n.locale === 'en' ?  item.resolve : item.resolve }}</b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { complaindetails } from '../../api/routes'
export default {
  name: 'Approve',
  props: ['item'],
  data () {
    return {
      circularLoading: false,
      baseUrl: trainingElearningServiceBaseUrl,
      valid: null,
      saveBtnName: this.$t('globalTrans.approve'),
      errors: [],
      userData: {},
      formData: {
        resolve: ''
      }
    }
  },
  created () {
   if (this.item) {
    this.details()
      this.formData.id = this.item.id
    }
  },
  watch: {
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    async details () {
          this.circularLoading = true
          const serchData = {
            complain_by: this.item.complain_by
          }
          const result = await RestApi.getData(trainingElearningServiceBaseUrl, complaindetails, serchData)
          if (!result.success) {
              this.userData = {}
          } else {
              this.userData = result.data
          }
          this.circularLoading = false
    }
  }
}
</script>
